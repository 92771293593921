import React, { Fragment, useState } from 'react';
import api from 'constants/api';
import AxiosPageReadyWrapper from 'components/AxiosPageReadyWrapper';
import GqlQueryList from 'vendor/GqlQueryList';
import { getOrdersListQuery } from 'components/Orders/query';
import Coupon from 'components/Coupons';
import Orders from 'components/Orders';
import Sales from 'components/Sales';
import LeadsList from 'components/Submissions/components/Dashboard/LeadsList';
import SubmissionsList from 'components/Submissions/components/Dashboard/SubmissionsList';
import { getSalesListQuery } from 'components/Sales/query';
import IconHeader from 'components/IconHeader';
import Add from '@bonlineza/luxity-lib/svg/Add';
import Minus from '@bonlineza/luxity-lib/svg/Minus';
import SvgWrapper from '@bonlineza/luxity-lib/svg/SvgWrapper';
import Accordion from '@bonlineza/luxity-lib/Accordion';
import {
  getUserLuxityLeadsQuery,
  getUserFormSubmissionsQuery,
} from 'components/Submissions/query';
import { leadStatuses } from 'constants/leadStatuses';
import { submissionStatuses } from 'constants/submissionStatuses';
import NoSale from '../../components/NoSale';

const Dashboard = () => {
  const initialResultLimit = 5;
  const [profile, setProfile] = useState(null);
  const [submissionsEmpty, setSubmissionsEmpty] = useState(false);
  const [leadsEmpty, setLeadsEmpty] = useState(false);
  const bothListsEmpty = submissionsEmpty && leadsEmpty;

  const AddIcon = () => (
    <SvgWrapper>
      <Add />
    </SvgWrapper>
  );
  const CloseIcon = () => (
    <SvgWrapper>
      <Minus />
    </SvgWrapper>
  );
  const OrdersIconHeader = () => (
    <div className="orders-icon-header">
      <IconHeader title="Orders" svgName="my_order" />
    </div>
  );
  const SalesIconHeader = () => (
    <div className="orders-icon-header ">
      <IconHeader title="Sales" svgName="my_sale" />
    </div>
  );
  const SubmissionsIconHeader = () => (
    <div className="orders-icon-header ">
      <IconHeader title="Submissions" svgName="submissions" />
    </div>
  );
  return (
    <Fragment>
      <Coupon showInviteAFriend />
      <AxiosPageReadyWrapper
        url={api.user.GET_PROFILE}
        onData={(res) => setProfile(res.data)}>
        <Accordion
          identifier="orders"
          containerClass="dashboard-accordion-container"
          closeIndicator={CloseIcon}
          openIndicator={AddIcon}
          title={OrdersIconHeader()}
          defaultHeight="auto">
          <GqlQueryList
            viewAllLink="/orders"
            showIncreaseButton
            showPageNavButtons
            initialLimitState={initialResultLimit}
            gqlQueryFn={() => getOrdersListQuery(initialResultLimit)}
            listKeyName="client_sale_list">
            <Orders profile={profile} />
          </GqlQueryList>
        </Accordion>

        <Accordion
          identifier="submissions"
          containerClass="dashboard-accordion-container"
          closeIndicator={CloseIcon}
          openIndicator={AddIcon}
          title={SubmissionsIconHeader()}
          defaultHeight="auto">
          {bothListsEmpty && <NoSale />}
          <GqlQueryList
            viewAllLink="/submissions"
            showIncreaseButton
            showPageNavButtons
            initialLimitState={initialResultLimit}
            gqlQueryFn={() =>
              getUserFormSubmissionsQuery(initialResultLimit, false, [
                submissionStatuses.NEW,
              ])
            }
            onDataReceived={(data) => setSubmissionsEmpty(data.length === 0)}
            listKeyName="user_form_submissions">
            <SubmissionsList />
          </GqlQueryList>
          <GqlQueryList
            viewAllLink="/submissions"
            showIncreaseButton
            showPageNavButtons
            initialLimitState={initialResultLimit}
            gqlQueryFn={() =>
              getUserLuxityLeadsQuery(initialResultLimit, profile.email, [
                leadStatuses.SENT,
                leadStatuses.ACCEPTED,
                leadStatuses.RECEIVED,
              ])
            }
            onDataReceived={(data) => setLeadsEmpty(data.length === 0)}
            listKeyName="luxity_leads">
            <LeadsList />
          </GqlQueryList>
        </Accordion>

        <Accordion
          identifier="sales"
          containerClass="dashboard-accordion-container"
          closeIndicator={CloseIcon}
          openIndicator={AddIcon}
          title={SalesIconHeader()}
          defaultHeight="auto">
          <GqlQueryList
            viewAllLink="/sales"
            showIncreaseButton
            showPageNavButtons
            initialLimitState={initialResultLimit}
            gqlQueryFn={() =>
              getSalesListQuery(initialResultLimit, profile.email)
            }
            listKeyName="client_purchase_list">
            <Sales />
          </GqlQueryList>
        </Accordion>
      </AxiosPageReadyWrapper>
    </Fragment>
  );
};

export default Dashboard;
