/** @format */

import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { fetchSvgFromStorage } from './actions';

const svgList = [
  'arrow_right',
  'calendar_circled',
  'calendar',
  'close',
  'file_attach_circled',
  'file_attach',
  'pointer_down',
  'check_circled',
  'tick',
  'notification',
  'edit',
  'eye',
  'account',
  'my_order',
  'my_payment',
  'my_profile',
  'my_sale',
  'search',
  'burger_menu',
  'luxity_menu',
  'close',
  'add',
  'minus',
  'mobile_avatar',
  'light_bulb',
  'logo',
  'accessories_category',
  'bags_category',
  'shoes_category',
  'facebook',
  'instagram',
  'pinterest',
  'twitter',
  'luxity-logo',
  'show_more_vertical',
  'cancel_order',
  'download',
  'my_coupon',
  'refer_a_friend',
  'send',
  'alert',
  'submissions',
  'available_credits',
  'customer_credit_payment',
  'vip',
];

class GetSvg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
    };
  }

  state;

  componentWillMount() {
    this.getSvgProcess();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.svg !== this.props.svg) {
      this.getSvgProcess(nextProps.svg);
    }

    this.setState((prevState) => ({
      ...prevState,
      wrapperClass: nextProps.wrapperClass,
    }));
  }

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.wrapperClass !== this.state.wrapperClass ||
      nextProps.svg !== this.props.svg ||
      nextProps.svgPointer !== this.props.svgPointer
    );
  }

  getSvgProcess = (svgOverride = '') => {
    if (this.makeSureThatSafe(svgOverride || this.props.svg)) {
      this.props.fetchSvg(svgOverride || this.props.svg);
    }

    return true;
  };

  makeSureThatSafe = (svg) => svgList.includes(svg);

  render() {
    return (
      <span className={`icon ${this.state.wrapperClass}`}>
        <span
          title={this.props.title}
          className="icon__inner"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: this.props.svgPointer }}
        />
      </span>
    );
  }
}

const mapState = (state, { svg }) => ({
  svgPointer: state.svgStore[svg],
});

const mapActions = (dispatch) => ({
  fetchSvg(svgName) {
    return fetchSvgFromStorage(svgName, dispatch);
  },
});

GetSvg.propTypes = {
  svgPointer: PropTypes.string,
  title: PropTypes.string,
  svg: PropTypes.string.isRequired,
  wrapperClass: PropTypes.string,
  fetchSvg: PropTypes.func.isRequired,
};

GetSvg.defaultProps = {
  wrapperClass: '',
  title: '',
  svgPointer: '',
};

export default connect(mapState, mapActions)(GetSvg);
